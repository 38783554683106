const title = `Quick Letter - Your template generator online`;

const subtitle = `Economize tempo. Não palavras.`;

const renderP1 = () => (
  <>
    Você já ficou cansado de ficar encarando uma página em branco, tentando
    encontrar as palavras perfeitas para expressar o que sente?
    <br />
    Ou fica com receio de escrever e-mails, cartões de aniversário ou até mesmo
    cartas de demissão?
    <br />
    Pois não precisa mais se preocupar!
  </>
);

const renderP2 = () => (
  <>
    <strong>QuickLetter</strong> vai te proteger como um cobertor quentinho em
    uma noite fria.
  </>
);

const renderP3 = () => (
  <>
    Com o nosso gerador de texto, você pode criar cartas bonitas e
    personalizadas para qualquer ocasião em apenas alguns cliques.
    <br />
    Então, se quiser declarar o seu amor para aquela pessoa especial, sair do
    emprego com estilo ou apenas dizer &quot;obrigado&quot;, nós temos um modelo
    que vai te ajudar a parecer um verdadeiro gênio literário.
  </>
);

const renderP4 = () => (
  <>
    Deixe o QuickLetter ser a sua arma secreta para criar cartas incríveis que
    farão seus amigos, familiares e colegas de trabalho exclamarem
    &quot;Uau!&quot;.
  </>
);

const ctaBtn = `Vamos começar?`;

export const homePageLocale = {
  title,
  subtitle,
  renderP1,
  renderP2,
  renderP3,
  renderP4,
  ctaBtn,
};
