const title = `Quick Letter - Your template generator online`;

const subtitle = `Save time. Not words.`;

const renderP1 = () => (
  <>
    Are you tired of staring at a blank page, trying to come up with the perfect
    words to express how you feel?
    <br />
    Do you dread writing emails, birthday cards, or even resignation letters?
    <br />
    Well, fear no more!
  </>
);

const renderP2 = () => (
  <>
    <strong>QuickLetter</strong> has got you covered like a cozy blanket on a
    chilly night.
  </>
);

const renderP3 = () => (
  <>
    With our easy-to-use template generator, you can create beautiful,
    customized letters for any occasion in just a few clicks.
    <br />
    Whether you&apos;re declaring your love to that special someone, quitting
    your job in style, or just saying &quot;thank you,&quot; we&apos;ve got a
    template that will make you sound like a literary genius.
  </>
);

const renderP4 = () => (
  <>
    Let QuickLetter be your secret weapon for crafting amazing letters that will
    make your friends, family, and colleagues go &quot;Wow!&quot;
  </>
);

const ctaBtn = `Get started`;

export const homePageLocale = {
  title,
  subtitle,
  renderP1,
  renderP2,
  renderP3,
  renderP4,
  ctaBtn,
};
