import Link from 'next/link';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { LogoSVG, RightArrowSVG } from '../SVGs';

export const Header = () => (
  <nav className="flex justify-between mb-10 items-center pb-6 relative">
    <div className="absolute h-px bg-slate-300 bottom-0 -right-6 -left-6"></div>
    <Link href="/">
      <span className="sr-only">Quick Letter</span>
      <LogoSVG />
    </Link>

    <ul className="flex gap-5 text-base">
      <li>
        <Link
          className="text-base font-semibold leading-6 text-gray-900"
          href="/"
        >
          Home
        </Link>
      </li>
      <li>
        <Link
          className="text-base font-semibold leading-6 text-gray-900"
          href="/use-cases"
        >
          Use cases
        </Link>
      </li>
      <li>
        <Link
          className="text-base font-semibold leading-6 text-gray-900"
          href="/about"
        >
          About
        </Link>
      </li>
      <li className="hidden md:block">
        <Link
          className="text-base font-semibold leading-6 text-gray-900 flex items-center gap-x-2"
          href="/template"
        >
          Get Started <RightArrowSVG height={20} width={20} />
        </Link>
      </li>
      <li>
        <LanguageSwitcher />
      </li>
    </ul>
  </nav>
);
