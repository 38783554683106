import { SVGProps } from 'react';

export const LogoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    zoomAndPan="magnify"
    viewBox="0 0 36 36.000001"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    height="48"
    width="48"
    {...props}
  >
    <defs>
      <clipPath id="0b6a220713">
        <path
          d="M 6.765625 30 L 24 30 L 24 35.027344 L 6.765625 35.027344 Z M 6.765625 30 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="586a24c24f">
        <path
          d="M 14 0 L 29.386719 0 L 29.386719 28 L 14 28 Z M 14 0 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <path
      fill="#000000"
      d="M 16.972656 31.21875 C 17.113281 31.167969 20.871094 31.160156 21.566406 31.152344 C 21.828125 31.152344 22.089844 31.152344 22.347656 31.148438 C 22.804688 31.144531 22.855469 31.222656 22.886719 30.933594 C 20.066406 30.9375 17.238281 30.914062 14.417969 30.933594 C 13.019531 30.945312 11.601562 30.949219 10.203125 30.933594 C 9.847656 30.929688 9.496094 30.9375 9.140625 30.933594 C 8.71875 30.929688 8.558594 30.890625 8.265625 31.113281 C 8.0625 31.273438 7.925781 31.398438 7.796875 31.699219 C 7.496094 32.386719 7.570312 33.132812 8.019531 33.738281 C 8.109375 33.863281 8.160156 33.890625 8.296875 33.988281 C 8.601562 34.203125 8.773438 34.148438 9.1875 34.148438 C 13.746094 34.15625 18.351562 34.113281 22.894531 34.148438 C 22.886719 34.03125 22.761719 33.714844 22.726562 33.554688 C 22.671875 33.324219 22.640625 33.132812 22.609375 32.890625 C 22.347656 32.824219 18.121094 32.863281 17.894531 32.769531 L 22.589844 32.675781 C 22.527344 32.308594 22.550781 31.917969 22.777344 31.269531 Z M 16.972656 31.21875 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <g clipPath="url(#0b6a220713)">
      <path
        fill="#000000"
        d="M 23.523438 30.085938 C 21.933594 30.042969 20.300781 30.097656 18.707031 30.082031 C 15.527344 30.058594 12.261719 30.085938 9.070312 30.085938 C 8.578125 30.085938 8.453125 30.046875 8.046875 30.253906 C 6.527344 31.027344 6.421875 33.285156 7.402344 34.324219 C 7.65625 34.589844 8.097656 34.953125 8.519531 35 C 8.765625 35.03125 17.667969 35.003906 18.730469 35.003906 C 20.320312 35.003906 21.9375 35.035156 23.527344 35.003906 L 23.402344 34.367188 C 23.070312 34.335938 22.625 34.359375 22.28125 34.359375 L 8.886719 34.359375 C 8.480469 34.363281 8.308594 34.285156 8.0625 34.085938 C 7.160156 33.359375 7.21875 31.640625 8.085938 30.980469 C 8.378906 30.753906 8.511719 30.722656 8.957031 30.722656 C 9.324219 30.722656 9.695312 30.722656 10.0625 30.722656 C 11.546875 30.726562 13.027344 30.722656 14.511719 30.722656 C 15.996094 30.726562 17.476562 30.722656 18.960938 30.722656 C 19.34375 30.722656 23.128906 30.746094 23.402344 30.714844 Z M 23.523438 30.085938 "
        fillOpacity="1"
        fillRule="evenodd"
      />
    </g>
    <path
      fill="#000000"
      d="M 16.738281 25.855469 C 16.738281 25.855469 16.386719 25.851562 15.6875 25.84375 C 15.6875 25.84375 15.6875 25.84375 15.683594 25.84375 C 15.613281 25.84375 15.480469 25.839844 15.328125 25.839844 C 15.234375 25.835938 15.136719 25.835938 15.035156 25.835938 C 14.75 25.832031 14.464844 25.835938 14.382812 25.859375 C 14.355469 25.910156 14.367188 25.960938 14.34375 25.996094 C 14.300781 26.058594 14.273438 26.019531 14.195312 26.058594 C 14.160156 26.3125 14.292969 26.710938 14.335938 26.957031 C 13.878906 27.066406 13.261719 27.550781 13.042969 27.777344 C 12.695312 28.128906 12.058594 29.101562 12.183594 29.511719 C 12.234375 29.675781 12.320312 29.691406 12.496094 29.726562 C 13.222656 29.863281 14.804688 29.871094 15.550781 29.875 C 16.246094 29.875 17.898438 29.855469 18.585938 29.734375 C 18.957031 29.667969 19.035156 29.523438 18.890625 29.097656 C 18.730469 28.621094 18.433594 28.148438 18.089844 27.785156 C 17.886719 27.570312 17.222656 27.054688 16.785156 26.957031 C 16.832031 26.710938 16.960938 26.316406 16.925781 26.058594 C 16.816406 26.003906 16.792969 26.066406 16.757812 25.945312 C 16.746094 25.898438 16.757812 25.894531 16.738281 25.855469 Z M 16.738281 25.855469 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <g clipPath="url(#586a24c24f)">
      <path
        fill="#000000"
        d="M 15.820312 27.226562 C 14.464844 24.980469 16.421875 22.449219 15.832031 21.144531 C 15.753906 20.960938 13.945312 18.988281 14.960938 16.617188 C 15.097656 17.128906 14.972656 17.496094 15.347656 17.878906 C 16.03125 11.679688 18.054688 8.414062 22.339844 4.5625 C 23.875 3.179688 27.523438 0.539062 29.386719 0.0117188 C 28.144531 1.644531 27.296875 4.4375 26.925781 6.605469 C 26.226562 10.707031 26.242188 9.265625 23.277344 11.695312 C 24.171875 11.335938 24.734375 10.90625 25.863281 10.65625 C 25.4375 11.835938 24.984375 12.652344 24.644531 14.007812 C 23.726562 17.699219 25.28125 17.972656 21.898438 18.347656 C 21.496094 18.394531 21.140625 18.464844 20.800781 18.578125 C 20.304688 18.746094 20.210938 18.777344 19.949219 19.128906 C 21.089844 19.027344 21.566406 18.746094 22.910156 18.902344 C 21.988281 19.242188 21.710938 19.152344 20.441406 19.71875 C 19.824219 19.996094 19.914062 19.976562 19.773438 20.152344 C 20.394531 20.25 21.125 20.148438 21.53125 20.453125 C 19.539062 20.492188 17.769531 22.003906 17.226562 22.253906 C 17.726562 19.214844 18.277344 16.152344 19.410156 13.210938 C 20.6875 9.894531 21.683594 8.863281 23.4375 6.226562 C 21.554688 7.585938 19.597656 10.777344 18.597656 13.617188 C 17.554688 16.570312 16.199219 24.144531 15.820312 27.226562 Z M 23.492188 6.15625 C 23.519531 6.234375 23.605469 5.957031 23.472656 6.109375 C 23.28125 6.328125 23.492188 6.152344 23.492188 6.15625 Z M 19.800781 20.269531 C 19.644531 20.210938 19.621094 20.203125 19.621094 20.203125 C 19.316406 20.109375 19.660156 20.367188 19.800781 20.269531 Z M 19.800781 20.269531 "
        fillOpacity="1"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
