export const Footer = () => (
  <>
    <footer className="py-4 relative text-right">
      <div className="absolute h-px bg-slate-300 top-0 -right-6 -left-6"></div>
      <span className="text-xs text-gray-500">
        © 2023{' '}
        <a
          href="https://www.esoftwaresolutions.com.au/"
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          eSoftware Solutions™
        </a>
        . All Rights Reserved.
      </span>
    </footer>
  </>
);
