import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { GoogleAnalytics } from 'nextjs-google-analytics';

const App = ({ Component, pageProps }: AppProps) => (
  <>
    {/* Google Adsense */}
    <script
      async
      src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.NEXT_PUBLIC_GOOGLE_ADSENSE}`}
      crossOrigin="anonymous"
    />

    <GoogleAnalytics
      trackPageViews
      gaMeasurementId={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}
    />

    <div className="p-6 max-w-6xl mx-auto">
      <Header />

      <div className="mb-8">
        <Component {...pageProps} />
      </div>

      <Footer />
    </div>
  </>
);

export default App;
