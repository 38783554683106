import { useRouter } from 'next/router';
import { ChangeEvent } from 'react';
import * as en from '../../locales/index/en';
import * as pt from '../../locales/index/ptBR';

export const LanguageSwitcher = () => {
  const router = useRouter();
  const { locale } = router;
  const t = locale === 'en' ? en : pt;

  const changeLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    const locale = e.target.value;
    router.push(router.pathname, router.asPath, { locale });
  };

  return (
    <select
      className="text-xs py-0 pr-6 border-none bg-transparent font-semibold leading-6 text-gray-900"
      onChange={changeLanguage}
      defaultValue={locale}
    >
      <option value="en">EN</option>
      <option value="pt-BR">PT</option>
    </select>
  );
};
